import React from 'react'
import { Helmet } from 'react-helmet'

import HeroContact from '../components/hero-contact'
import Mapbox from '../components/mapbox'
import Layout from '../components/layout'

class Contact extends React.Component {
    render() {
    const siteTitle = 'L\'Atelier Info | Contactez-nous !'
    const siteDesc = 'Voici nous informations de contact, n\'hésitez pas à nous contacter pour toute demande d\'information'
    const pageTitle = 'Contactez-nous !'
        return (
            <Layout location={this.props.location}>
                <div className="page-wrapper">
                    <Helmet title={siteTitle}>
                        <meta name="description" content={siteDesc} />
                        <link href="https://api.tiles.mapbox.com/mapbox-gl-js/v1.3.1/mapbox-gl.css" rel="stylesheet" />
                    </Helmet>

                    <HeroContact title={pageTitle} imgpage="contact"/>
                    <Mapbox/>
                </div>
            </Layout>
        )
    }
}

export default Contact

