import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import styles from './mapbox.module.scss'

mapboxgl.accessToken ="pk.eyJ1IjoibWF0dGhpZXUtYWxpdSIsImEiOiJjbDN1YjZvMnUyMHZhM2prY2kydHQ5c2lxIn0._bb2gJiHOz0VSnjbBzH3_g"

class Mapbox extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        lng: -73.27270264404461,
        lat: 45.43984653223817,
        zoom: 15
     };
   }

   componentDidMount() {
        const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [this.state.lng, this.state.lat],
        zoom: this.state.zoom
        });
        const geojson = {
            type: 'FeatureCollection',
                features: [{
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [this.state.lng, this.state.lat]
                    },
                    properties: {
                        title: 'Marker 1',
                        description: '1520 Bd Industriel, Chambly'
                    }
                }]
        };
        geojson.features.forEach(function(marker) {
            // create a HTML element for each feature
            const el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);

            const elPulse = document.createElement('div');
            el.insertAdjacentElement('beforeend', elPulse);
            elPulse.className = 'pulse';
        });
    }

    render() {
        return (
            <section>
                <h2 className={`${styles.mapTitle} container`}>Nous Trouver</h2>
                <div style={{ height: '500px', width: '100%' , position: 'relative', overflow: 'hidden'}}>
                    <div ref={el => this.mapContainer = el} className={styles.mapContainer} />
                </div>
            </section>
        )
    }
 }

 export default Mapbox