import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import CircleImg from '../images/svg/main-circle.svg'

import styles from './hero-contact.module.scss'

export default ({title,imgpage}) => {

	return (
		<StaticQuery
		query={graphql`
			query {
				contact: file(relativePath: { eq: "contact/illustration-contact.png" }) {
					childImageSharp {
						fluid(maxWidth: 750, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={data => (
			<section className={`${styles.herocontact} container`}>
				<CircleImg className={styles.circleimg}/>
				<div className={styles.herocontactImgillusWrapper}>
					<Img className={styles.herocontactImgillusItem} fluid={[data[imgpage].childImageSharp.fluid]}/>
				</div>
				<div className={styles.herocontactDetails}>
				<h1 className={styles.herocontactDetailsTitle}>{title}</h1>
					<p>
					1520 Boulevard Industriel,<br/>
					Chambly, QC, J3L 6Z7<br/>
						Téléphone : <a className="link link--primary" href="tel:4506585005">450-658-5005</a><br/>
						Courriel : <a className="link link--primary" href="mailto:contact@latelierinfo.com">contact@latelierinfo.com</a><br/>
				</p>
				</div>
			</section>
		)}
		/>
	)
}